<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="onUpload" icon="el-icon-download" type="defalut">导出</el-button>
                <el-button size="mini" @click="onUpload" icon="el-icon-upload" type="defalut">导入</el-button>
                <el-button size="mini" @click="onAdd" icon="el-icon-plus" type="defalut">新建</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="成品菜" />
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="分类">
                    <el-input v-model="search.type" placeholder="请输入" clearable />
                </el-form-item>
                <el-form-item label="名称">
                    <el-input v-model="search.name" placeholder="请输入" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="mini" v-loading="loading" width="100%" height="100%"
                    :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                        :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
                    <el-table-column prop="Names" label="名称"  />
                    <el-table-column prop="SortNames" label="分类"  />
                    <el-table-column prop="CanCi" label="餐次"  />
                    <el-table-column prop="Methods" label="烹饪方法" width="" show-overflow-tooltip  />
                    <!-- <el-table-column align="center" width="120" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">
                            </el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            search: { type: 'appoint' },
            table: {
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            }
        }
    },
    methods: {
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/diet/dishes?page=${this.table.page}&pageSize=${this.table.pageSize}&status=1`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.data.records
                this.table.total = resp.data.data.total
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    padding-bottom: 10px;

    .btns {
        float: right;
    }
}

.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pager {
    padding-top: 10px;
    text-align: center;
}
</style>
